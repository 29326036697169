import { motion } from "framer-motion"
import TransitionLink from 'gatsby-plugin-transition-link'
import React, { useEffect, useRef, useState } from 'react'
import back from '../../images/ikony/goback.svg'
import paluch from '../../images/ikony/paluch.svg'
import tracker_poczatek from '../../images/nowy_tracker/poczatek@2x.png'
import tracker from '../../images/nowy_tracker/tracker@2x.png'
import korzysci from '../../images/podstrony/uslugi/korzysci.svg'
import krok1 from '../../images/podstrony/uslugi/media/1.svg'
import krok2 from '../../images/podstrony/uslugi/media/2.svg'
import krok3 from '../../images/podstrony/uslugi/media/3.svg'
import krok4 from '../../images/podstrony/uslugi/media/4.svg'
import krok5 from '../../images/podstrony/uslugi/media/5.svg'
import branding_bg from '../../images/podstrony/uslugi/okladka_media@2x.png'
import typo from '../../images/podstrony/uslugi/okladka_tekst_media@2x.png'
import okladka from '../../images/seo/media.jpg'
import ButtonPaluch from '../buttons/button_goto'
import Formularz from '../formularz/formularz'
import Rekomendacje from '../rekomendacje'
import Seo from '../seo'
import Slider from '../slider/slider'
import InneUslugi from './inne'
import UslugaKrok from './usluga_krok'









const UslugaMedia = ({title, entry}) => {    

    const [krokAktywny, ustawKrok] = useState();

    const kroki = useRef()
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const ref5 = useRef(null)
    const ref6 = useRef(null)
    const pas = useRef()

    var refs = [ref1, ref2, ref3, ref4]

    var pasCrop;


    useEffect(() => {

  
        
        
        return () => {

        }
    }, []);

    

    const list = ['Strategia komunikacji', 'Media direction', 'Social media', 'Kampanie reklamowe', 'Fotografia & wideo', ];
    const item = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -100 },
    }
    const item2 = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: 100 },
    }


    function LinkPrzycisk(props){

        if(props.tekst) {
          return(
            <a href={props.link} className="bread-link">
       
                <img src={paluch} />
                <span className="przycisk">{props.tekst}</span>
           
            </a>
          )
        }
        else{ 
          return(<></>)
        }
            
        
    
         
        }



    const variants = {
        visible: i => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.3 + 0.6,
            },
        }),
        hidden: { opacity: 0, y: 50 },
    }


    useEffect(() => {

        
        console.log(Slider);

     
    }, [krokAktywny]);








    return (
        <>
        <Seo title="MEDIA" description="Projektujemy wyjątkowe interakcje. Zadbamy o Twoje środowisko w mediach - współpracowaliśmy z wiodącymi ekosystemami mediowymi w Polsce." image={okladka} />
        <div className="usluga-okladka-wrapper">
                        <img className="usluga-okladka" src={typo} />
                        <img className="usluga-okladka" src={branding_bg} />
                        </div>
        <div className="podstrona-wrapper" id="usluga-info" onClick={() => (console.log({ krokAktywny }))}>

        

            <div className="usluga-wrapper usluga-aktywna">
                <div id="usluga-rzad" className="usluga-zaladowana">
                    <div className="usluga-rzad">
                        <TransitionLink 
                        state={{ title: 'USŁUGI' }}
                        activeClassName="podstrona-aktywna"
                        to="/uslugi"
                        partiallyActive={true}
                        exit={{
                          length: 1,
                          state: { x: -entry.state.len},
                        }}
                        entry={{
                          length: 1,             
                          state:  {x: (entry.state.len), len: entry.state.len},
                        }}
                        
                        
                        >
                            <img

                                src={back}
                                className="usluga-wroc"
                            />
                        </TransitionLink>

                        <div className="usluga-header-maska"></div>
                        <h1>{title}</h1>
                        <ul>

                            {list.map((l) => (
                                <p
                                    key={l}




                                >{l}</p>
                            ))}


                        </ul>
                        <div



                            className="usluga-przycisk" style={{
                                height: '100%'
                            }}>
                            <ButtonPaluch
                                text="ZOBACZ WIĘCEJ" />
                        </div>
                        <div className="usluga-linia-bread"></div>
                        <div className="bread-dodatkowe">
                        <LinkPrzycisk  link="/formularz" tekst="KONTAKT" />
                        <LinkPrzycisk  link="/projekty" tekst="PROJEKTY" />
                        </div>
                    </div>



                    <div 
                     className='usluga-opis-wrapper' >

                        
                        
                        <motion.div
                            
                            animate={{ y: 0 }}
                            transition={{ ease: "easeOut", duration: 2 }}
                            style={{   position: 'relative' }}
                            className={(!krokAktywny ? "usluga-wjazd" : "usluga-wjazd kroki-aktywne")}
                            >
                            <h6>Projektujemy wyjątkowe interakcje</h6>
      <h4>Zadbamy o Twoje środowisko w mediach. Jako specjaliści w dziedzinie brandingu i komunikacji, zaplanujemy Twoją obecność w przestrzeni publicznej, wyprodukujemy treści i zrealizujemy cele postawione przez Twój biznes. Tworzymy zintegrowane kampanie reklamowe, obsługujemy obecność marki w mediach lub w ich wycinku, np. w mediach społecznościowych. Jesteśmy samowystarczalni we wszystkich obszarach produkcyjnych, od koncepcji treści przez copy, grafikę do filmu i animacji. Współpracowaliśmy z wiodącymi ekosystemami mediowymi w Polsce.</h4>
                            
                        
                            <div className="usluga-kroki">
                                <h6>Nasze kompetencje</h6>


                                <div>
                                    <div ref={ref1} className="krok-wrap" id="krok1">

                                        <UslugaKrok
                                        img={krok1}
                                        tytul="Strategia komunikacji"
                                        podtytul="Efektywna obecność w mediach"
                                        tekst="Strategia komunikacji określa długofalowo ton komunikacji (tone of voice) i główne komunikaty (key messages), które marka będzie przekazywała jako swój fundament wizerunkowy. Mając zdefiniowaną markę i znając problemy oraz korzyści, których szukają potencjalni klienci, możemy stworzyć koncepcję komunikacji dla Twojej marki. Na tym etapie określimy media, które będą najbardziej efektywne dla Twojego biznesu oraz zestaw zachowań i obszarów, które zagospodarujesz."
                                        
                                        krokId="1" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>
                                    <div ref={ref2} className="krok-wrap" id="krok2">

                                        <UslugaKrok 
                                        img={krok2}
                                        tytul="Media direction"
                                        podtytul="Efektywna obecność w mediach"
                                        tekst="Prowadzimy kompleksową obsługę Twojej marki w mediach. Inspirujemy działania, tworzymy strategie contentowe, plany komunikacji, obsługujemy platformy i tworzymy treści. Obsługujemy ekosystem wewnętrzny - Twoją stronę i media społecznościowe, współpracujemy z mediami zewnętrznymi a także wspieramy Twoją obecność offline." krokId="2" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>
                                    <div ref={ref3} className="krok-wrap" id="krok3">

                                        <UslugaKrok 
                                        img={krok3}
                                        tytul="Social media"
                                        podtytul="Angażujemy Twoich klientów w codziennej komunikacji"
                                        tekst="Media społecznościowe nie mają przed nami żadnych tajemnic. W zależności od postawionych celów, preferencji Twoich klientów i strategii komunikacji, zarządzamy obecnością i dbamy o wizerunek Twojej marki na odpowiednich platformach. Facebook, Instagram, YouTube, TikTok czy Twitter - pomożemy Ci wycisnąć maksa z każdej platformy. Zajmiemy się stworzeniem strategii treści, planowaniem, produkcją i obsługą kanałów." 
                                        krokId="3" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>
                                    <div ref={ref4} className="krok-wrap" id="krok4">

                                        <UslugaKrok
                                        img={krok4}
                                        tytul="Kampanie reklamowe"
                                        podtytul="Realizujemy Twoje cele w mediach"
                                        tekst="Znasz swoje cele biznesowe a my pomożemy Ci dostosować odpowiednie rozwiązania aby je zrealizować. Przeanalizujemy Twój problem, wysłuchamy Twoich użytkowników i stworzymy odpowiednią koncepcję, która dotrze do nich z oczekiwanym przekazem w precyzyjnie dobranych mediach. Zajmiemy się również produkcją elementów kampanii i wdrożeniem, także o nic nie musisz się martwić. Śledź rezultaty i realizuj swoje plany." 
                                        krokId="4" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>

                                    <div ref={ref5} className="krok-wrap" id="krok4">

                                    <UslugaKrok
                                    img={krok5}
                                    tytul="Fotografia & wideo"
                                    podtytul="Inspirujące doświadczenie marki"
                                    tekst="Mamy ponad 10 lat doświadczenia w roli fotografów. Realizowaliśmy reportaże z największych wydarzeń sportowych, pracowaliśmy podczas wydarzeń muzycznych czy eventów reklamowych. Zajmujemy się fotografią studyjną i zrealizowaliśmy dziesiątki sesji zdjęciowych. Tworzymy także zdjęcia produktowe. W obszarze produkcji wideo działamy w zespole z najlepszymi twórcami, którzy udowadniali swoje umiejętności w znanych polskich i zagranicznych produkcjach filmowych. Stworzymy projekty dedykowane do Twoich mediów społecznościowych, kampanii reklamowych, pomożemy Ci wyprodukować kanał YouTube a także wesprzemy Cię w projektach artystycznych." 
                                    krokId="5" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>

                                    

                                </div>
                            </div>



                        </motion.div>

                        <div className="usluga-proces">
                            <h6>Proces</h6>
                            <div className="usluga-proces-tracker">
                                <img src={tracker_poczatek} />
                                <div className="tracker-pas">
                                    <div className="tracker-punkt"></div>
                                    <div className="tracker-punkt"></div>
                                    <div className="tracker-punkt"></div>
                                    <div className="tracker-punkt"></div>
                                    <div className="tracker-linia"></div>
                                    
                                </div>
                                <div className="tracker-wrapper">
                                <img className="tracker" src={tracker} />
                                </div>

                                
                                
                                
                            </div>

                            <div className="usluga-proces-kroki">
                                <div className="usluga-proces-krok">
                                    <span className="przycisk">1. ANALIZA</span>
                                    <p>Realna wiedza o Twojej organizacji, klientach i konkurencji jest kluczowa dla realizowania jakościowych projektów brandingowych. W Odrze jesteśmy gotowi by dostać się do sedna sprawy, z wykorzystaniem profesjonalnych metod badawczych</p>      
                                </div>
                                <div className="usluga-proces-krok">
                                    <span className="przycisk">2. KONCEPCJA</span>
                                    <p>Tworzymy dedykowane koncepcje oparte na danych pochodzących od Ciebie i z etapu badań. Nie powielamy schematów, bo wiemy jak ważna jest unikalność Twojego biznesu.</p>      
                                </div>
                                <div className="usluga-proces-krok">
                                    <span className="przycisk">3. PRODUKCJA</span>
                                    <p>Kompleksowo produkujemy treści do Twoich mediów. Od materiałów pisanych, przez elementy graficzne do fotografii, animacji i filmów. Dostarczamy wszystkiego, czego potrzebujesz.</p>      
                                </div>
                                <div className="usluga-proces-krok">
                                    <span className="przycisk">4. OBSŁUGA</span>
                                    <p>Inspirujemy zmianę. Prowadzimy bieżącą obsługę Twoich mediów - od etapu koncepcji, przez miesięczne planowanie, tworzenie treści do publikacji i administracji profili. Budujemy relacje z mediami okołobranżowymi. Wszystko, by zrealizować postawione przez Ciebie cele.   </p>      
                                </div>
                            </div>

                        </div>

                        <div className="usluga-wspolpraca">
                         
                        <h6>Modele współpracy</h6>
                            <div>
                            <div className="box-szary">
                                <span className="przycisk">Model projektowy</span>
                                <div>
                                <div className="kolumna-info">
                                    <img src={korzysci} />
                                    <p>Praca w modelu projektowym najlepiej sprawdza się przy jednorazowych realizacjach, które posiadają określony zestaw składowych. W tym modelu przygotowujemy wstępną wycenę przed rozpoczęciem działań.</p>
                                </div>
                                <div className="kolumna-info">
                                 
                                    <p>Choć taki sposób działania wydaje się wygodny, należy pamiętać o tym, że jest on mniej odporny na zmiany w czasie trwania projektu. Tym, którym zależy na rozwijaniu realizowanych koncepcji rekomendujemy pracę w modelu ryczałtowym.</p>
                                </div>
                                </div>
                            </div>
                            <div className="box-szary">
                                <span className="przycisk">Model ryczałtowy - abonament</span>
                                <div>
                                <div className="kolumna-info">
                                    <img src={korzysci} />
                                    <p>W modelu ryczałtowym współpracujemy głównie z klientami, których obsługujemy w sposób ciągły. Polega on na ustaleniu określonej miesięcznej kwoty, która przeliczona jest na ilość godzin i kompetencje wykorzystywane w projekcie.</p>
                                </div>
                                <div className="kolumna-info">
                                  
                                    <p>Dzięki posiadanemu abonamentowi zawsze jesteśmy gotowi na Twoje briefy a Twoje zlecenia wpadają na początek kolejki. Taka forma współpracy pozwala na znacznie większą elastyczność i zapewnia bezpieczeństwo Twojej komunikacji.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <Rekomendacje />
                        

                        
                        
                    </div>
                    <Slider />
                </div>
            </div>
            <h6 className="zobacz-inne-uslugi" >Zobacz inne usługi</h6>
            
        </div>
        <InneUslugi  usun="MEDIA" />
        <Formularz />
        </>
    )


}



export default UslugaMedia
