import { motion } from "framer-motion"
import TransitionLink from 'gatsby-plugin-transition-link'
import React from 'react'
import branding from '../../images/podstrony/uslugi/okladka_branding@2x.png'
import design from '../../images/podstrony/uslugi/okladka_design@2x.png'
import media from '../../images/podstrony/uslugi/okladka_media@2x.png'
import UslugaRzad from './usluga_rzad'
import UslugaRzadMobile from './usluga_rzad_mobile'







class InneUslugi extends React.Component {
  constructor(props) {
    super(props)
    this.zmienChlebora = this.zmienChlebora.bind(this)
    this.state = {
      breadCrumb: false,
      chowaj: false,
      chowaj2: false,
      tekst: false,
      design: false,
      media: false,
      branding: false,
      mobile: false,
    }
    this.eUsluga = React.createRef();
    this.main = React.createRef();
  }

    zmienChlebora = () => {
      
  }


  handleClick = () => {
    this.eUsluga.current.toggleFullScreen();
  }


  chowajReszte = () => {
    if(this.state.mobile) {
    
   
    }
    else {
      this.setState({
        tekst: !this.state.tekst,
        chowaj: !this.state.chowaj,
  
      })
    }
  }

  chowajReszte2 = () => {
    if(this.state.mobile) {
    
   
    }
    else {
      this.setState({
        tekst: !this.state.tekst,
        chowaj2: !this.state.chowaj,
      })
    }

  }


  chowajReszte3 = () => {
    if(this.state.mobile) {
    
   
    }
    else {
      this.setState({
        tekst: !this.state.tekst,
        chowaj3: !this.state.chowaj,
      })
    }
    
  }

  componentDidMount() {

    this.test = this.main.current.getBoundingClientRect();

    if(this.test.width < 1025) {
        this.setState({
            mobile: true,
        })
        
    }

    if(this.props.usun === "BRANDING") {
      this.setState({
        branding: true
      })
      console.log("BRANDING WYPADA");
    }

    if(this.props.usun === "MEDIA") {
      this.setState({
        media: true
      })
      console.log("MEDIA WYPADA");
    }

    if(this.props.usun === "DESIGN") {
      this.setState({
        design: true
      })
      console.log("DESIGN WYPADA");
    }

  }

  



  

 
 

  render() {


    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: 100 },
     }


     if(this.state.mobile) {
      return (<>
    
        <motion.div ref={this.main} className={this.state.chowaj || this.state.chowaj2 || this.state.chowaj3 ? (
          "chowaj-uslugi"
        ) : (
           ""
          )}>
       
        
        <motion.div className="uslugi-lista">
            <motion.div
              className={this.state.chowaj ? (
                "usluga-wybrana"
              ) : (
                  ""
                )}
              variants={item}
              style={this.state.branding ? {display: 'none'} : {}}
              onClick={this.chowajReszte}
  >
                <TransitionLink 
                to="/uslugi/branding"
                exit={{
                  length: 1.5,
                
                  state: { },
                }}
                style={{zIndex: '1000'}}
  
               
                entry={{
                  length: 1.5,
           
                  state:  {},
  
                }}>
              <UslugaRzadMobile img={branding}  usluga="BRANDING" ref={this.eUsluga} zmienChlebora={this.zmienChlebora} />
              </TransitionLink>
            </motion.div>
  
            <motion.div
              style={this.state.design ? {display: 'none'} : {}}
              className={this.state.chowaj2 ? (
                "usluga-wybrana"
              ) : (
                  ""
                )}
             
              onClick={this.chowajReszte2}
      >
                  <TransitionLink 
                   style={{zIndex: '1000'}}
                to="/uslugi/design"
                exit={{
                  length: 1.5,

                  state: { },
                }}
  
               
                entry={{
                  length: 1.5,

                  state:  {},
  
                }}>
              <UslugaRzadMobile img={design}   usluga="DESIGN" ref={this.eUsluga} zmienChlebora={this.zmienChlebora} />
              </TransitionLink>
            </motion.div>
  
            <motion.div
              className={this.state.chowaj3 ? (
                "usluga-wybrana"
              ) : (
                  ""
                )}
                style={this.state.media ? {display: 'none'} : {}}
              onClick={this.chowajReszte3}>
                 <TransitionLink 
                to="/uslugi/media"
                exit={{
                  length: 1.5,
                
                  state: { },
                }}
                style={{zIndex: '1000'}}
  
               
                entry={{
                  length: 1.5,
                
                  state:  {},
  
                }}>
              <UslugaRzadMobile usluga="MEDIA" img={media} ref={this.eUsluga} zmienChlebora={this.zmienChlebora} />
              </TransitionLink>
            </motion.div>
          </motion.div>
        
        
          </motion.div>
          </>
  
  
          
      
      )
     }

    else {
      return (<>
    
        <motion.div ref={this.main} className={this.state.chowaj || this.state.chowaj2 || this.state.chowaj3 ? (
          "chowaj-uslugi"
        ) : (
           ""
          )}
  >
       
        
        
       <motion.div className="uslugi-lista">
            <motion.div
              className={this.state.chowaj ? (
                "usluga-wybrana"
              ) : (
                  ""
                )}
              variants={item}
              style={this.state.branding ? {display: 'none'} : {}}
              onClick={this.chowajReszte}
  >
                <TransitionLink 
                to="/uslugi/branding"
                exit={{
                  length: 1.5,
                  delay: 1,
                  state: { x: 0, past: 'uslugi'},
                }}
  
               
                entry={{
                  length: 1.5,
                  appearAfter: 1,
                  state:  {x: 0, past: 'uslugi'},
  
                }}>
              <UslugaRzad img={branding}  usluga="BRANDING" ref={this.eUsluga} zmienChlebora={this.zmienChlebora} />
              </TransitionLink>
            </motion.div>
  
            <motion.div
              className={this.state.chowaj2 ? (
                "usluga-wybrana"
              ) : (
                  ""
                )}
                style={this.state.design ? {display: 'none'} : {}}
              onClick={this.chowajReszte2}
      >
                  <TransitionLink 
                to="/uslugi/design"
                exit={{
                  length: 1.5,
                  delay: 1,
                  state: { x: 0, past: 'uslugi'},
                }}
  
               
                entry={{
                  length: 1.5,
                  appearAfter: 1,
                  state:  {x: 0, past: 'uslugi'},
  
                }}>
              <UslugaRzad img={design}   usluga="DESIGN" ref={this.eUsluga} zmienChlebora={this.zmienChlebora} />
              </TransitionLink>
            </motion.div>
  
            <motion.div
              className={this.state.chowaj3 ? (
                "usluga-wybrana"
              ) : (
                  ""
                )}
                style={this.state.media ? {display: 'none'} : {}}
              onClick={this.chowajReszte3}>
                 <TransitionLink 
                to="/uslugi/media"
                exit={{
                  length: 1.5,
                  delay: 1,
                  state: { x: 0, past: 'uslugi'},
                }}
  
               
                entry={{
                  length: 1.5,
                  appearAfter: 1,
                  state:  {x: 0, past: 'uslugi'},
  
                }}>
              <UslugaRzad usluga="MEDIA" img={media} ref={this.eUsluga} zmienChlebora={this.zmienChlebora} />
              </TransitionLink>
            </motion.div>
          </motion.div>
        
    
          </motion.div>
          </>
  
  
          
      
      )
    }

   
    
  }
}

export default InneUslugi

