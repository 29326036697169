import React, { useState } from 'react'
import cytat from '../images/ikony/cytat.svg'
import paluch from '../images/ikony/paluch.svg'














const rekomendacje = [
  {
    "id": "1",
    "nazwa": "Betard",
    "imie": "Karolina Dziechcińska",
    "stanowisko": "Brand Manager",
    "firma": "Betard",
    "opis": "Postawiliśmy przed zespołem Odry duże wyzwanie i czujemy, że dostarczane rozwiązania są niezwykle świadome, jakościowe i nowoczesne. Proces redesignu marki przebiega bardzo sprawnie i kompleksowo a jego efektem jest odświeżenie i jeszcze większa profesjonalizacja wizerunku marki. Zaprojektowana strona internetowa jest zgodna z najnowszymi trendami, zarówno w obszarze technologicznym jak i w kontekście rozwiązań user experience oraz user inter- face. W ramach bieżącej współpracy czujemy wsparcie oraz ogromne zaangażowanie Odry, co jest dla nas niezwykle istotne.",
    "logo": "/images/referencje/logotypy/betard.svg"
  },
  {
    "id": "2",
    "nazwa": "Red Bull",
    "imie": "Marek Krynicki",
    "stanowisko": "Athletes’ Manager",
    "firma": "Red Bull",
    "opis": "Współpraca z Odra Studio to gwarancja profesjonalizmu, ale też olbrzymiej dawki kreatywności, dzięki niesamowitemu zespołowi specjalistów. Natomiast same działania Odra Studio na linii Red Bull x Maciej Janowski są stawiane przez nas jako best practice dla innych zawodników z naszego portfolio. Odra Studio wzorowo wywiązuje się z zleconych zadań dbając przy tym o najwyższą jakość usług i zachowanie unikalnej relacji i polecamy usługi wszystkim, którzy podobnie jak by cenią sobie współpracę na najwyższym poziomie.",
    "logo": "/images/referencje/logotypy/redbull.svg"
  },
  {
    "id": "3",
    "nazwa": "Maciej Janowski",
    "imie": "Maciej Janowski",
    "stanowisko": "Reprezentant Polski na żużlu",
    "firma": "",
    "opis": "Jednym z głównych atutów Firmy Odra Studio jest łączenie w sobie dopasowania się do potrzeb rynkowych jednoczenie kreując wizję klienta. Młody zespół zdolnych ludzi potrafi działać pod presją czasu absolutnie nie tracąc przy tym pro- fesjonalnego efektu końcowego. Są otwarci na pomysły, które potrafią w kreatywny sposób rozwinąć tak aby osiągnąć zamierzony cel marketingowy. Dzięki swojej znajomości rynku są również skorzy do negowania działań, które ich zdaniem są dla klienta niekorzystne lub nie przyniosą wymiernych efektów. W zamian proponując nam rozwiązania, które okazały się sukcesem. Firma Odra Studio jest głównym budowniczym mojej marki. Cenię sobie tą współpracę z wielu względów i przez lata nauczony pozytywnym doświadczeniem oraz zdobytym zaufaniem nauczyłem się opierać na ekspertyzie fachowców w tym zakresie i powierzać Firmie Odra Studio wszelkie sprawy związane z marketingiem czy reklamą i nie tylko. Firma Odra Studio jest na tyle elastyczna, iż potrafiła dostosować się do moich nietypowych potrzeb i efektywnie zarządza nie tylko reklamą ale całym procesem od etapu produkcji do sprzedaży produktów Team Janowski. ",
    "logo": "/images/referencje/logotypy/magic.svg"
  }, 
  {
   "id": "4",
   "nazwa": "Mobile Vikings",
   "imie": "Bartosz Grabowski",
   "stanowisko": "Dyrektor Marketingu",
   "firma": "Mobile Vikings",
   "opis": "Odra to zespół złożony ze świetnych indywidualności, doświadczony w bardzo ciekawych i niełatwych projektach. Nasza współpraca przebiegała bardzo płynnie, na prawdziwie partnerskich warunkach. Bardzo doceniam sposób w jaki zespół Odry wychodził z inicjatywą, dbał o jakość naszej komunikacji i angażował się w podnoszenie standardów marki Mobile Vikings.",
   "logo": "/images/referencje/logotypy/mv.png"
 }
 ]

// import { ParallaxProvider } from 'react-scroll-parallax';
//
// import { Parallax } from 'react-scroll-parallax';


const title = 'Rekomendacje'






export default function Onas() {

  const [activeTab, setActiveTab] = useState(0);

  const handleOnClick = index => {
    setActiveTab(index); 
    console.log(index);
  };

  const ilosc = rekomendacje.length
  console.log(ilosc)


  const next = () => {
    var next = parseInt(activeTab) + 1;
    if(next > (ilosc - 1)) {
      setActiveTab(0);
    }
    else {
      setActiveTab(next); 
    }

  };

  const prev = () => {
    var prev = parseInt(activeTab) - 1;
    if(prev < 0) {
      setActiveTab(parseInt(ilosc - 1));
    }
    else {
      setActiveTab(prev); 
    }
   
  };




  return(
    <>



      <div className="rekomendacje-wybor">
      {rekomendacje.map((p, index) => (
        <div key={index}
        onClick={() => handleOnClick(index)}
        className={activeTab === index ? "rekomendacje-pigulka pigulka-active" : "rekomendacje-pigulka"}
        >
          <span className="przycisk">{p.nazwa}</span>
        </div>
        
      ))}
      </div>

      <div className="rekomendacje-selekta">
        <div className="rekomendacje-lewo">
        <div className="rekomendacje-sterowanie">
        <div onClick={() => prev()} className="rekomendacje-prev">
            <img src={paluch} />
            <span className="przycisk">POPRZEDNI</span>
        </div>
        <div onClick={() => next()} className="rekomendacje-next">
            <img src={paluch} />
            <span className="przycisk">NASTĘPNY</span>
        </div>
        </div>


        {rekomendacje.map((p, index) => (
        <div key={index} className={activeTab === index ? "rekomendacje-info rek-info-active" : "rekomendacje-info"}> 
          <div><img src={cytat} />
          <img src={p.logo} /></div>
          <div><span className="przycisk">{p.imie}</span>
          <p>{p.stanowisko}<br />{p.firma}</p></div>
        </div>
        ))}

        </div>

        <div className="rekomendacje-tekst">
        {rekomendacje.map((p, index) => (
          <h4 key={index} className={activeTab === index ? "rekomendacja-act" : ""}>{p.opis}</h4>
        ))}
        </div>


      </div>
   
    




    </>
  )
}
