import React, { useState } from 'react'
import { Link } from 'gatsby'


import Bread from '../../components/bread'
import ButtonPaluch from '../../components/buttons/button_goto'
import { Flipper, Flipped } from 'react-flip-toolkit'

import SEO from '../../components/seo.js'
import Slider from '../../components/slider/slider'
import style from '../uslugi.css'

import UslugaMedia from '../../components/uslugi/media'


const title = "MEDIA"   


class Usluga extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
         
            <UslugaMedia title={title} entry={this.props.entry} />
            </>
        )
    }
}


export default Usluga
